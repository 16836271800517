import { NzConfig } from 'ng-zorro-antd/core/config';

/**
 * Configuration for ng-zorro.
 */
export const ngZorroConfig: NzConfig = {
  notification: { nzPlacement: 'bottomRight' },
  table: { nzSize: 'middle' },
  drawer: {
    nzMaskClosable: false,
  }
};
