<nz-layout>
  <nz-content>
    <img height="100px" [src]="loadingImg" alt="CondoPilot logo" />
    <nz-progress
      [nzPercent]="100"
      [nzStrokeColor]="{ '0%': '#7642FF', '100%': '#4259ff' }"
      nzStatus="active"
      [nzShowInfo]="false"
      nzSize="small"
    ></nz-progress>
  </nz-content>
</nz-layout>