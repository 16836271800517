import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { BannerModel } from '@app/models';
import { computed } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

export interface AppStateInterface {
  banner: BannerModel;
  showBanner: boolean;
  isSiderCollapsed: boolean;
  isMobile: boolean;
}

/**
 * Represents the application store.
 */
export const AppStore = signalStore(
  { providedIn: 'root' },
  withState<AppStateInterface>({
    showBanner: false,
    banner: { message: '' },
    isSiderCollapsed: false,
    isMobile: false,
  }),
  withComputed((store) => ({
    showBanner: computed(() => store.banner.message()),
  })),
  withMethods((store) => ({
    /**
     * Sets the banner message.
     * @param banner The banner model containing the message.
     */
    setBanner: (banner: BannerModel) => {
      patchState(store, { banner });
    },
    /**
     * Clears the banner message.
     */
    clearBanner: () => {
      patchState(store, { banner: { message: '' } });
    },
    toggleSider: (state?: boolean) => {
      if(state) {
        patchState(store, { isSiderCollapsed: state });
      } else {
        patchState(store, { isSiderCollapsed: !store.isSiderCollapsed() });
      }
    },
    setIsMobile: (state: boolean) => {
      patchState(store, { isMobile: state });
    }
  })),
);
