import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { UserRoleModel } from '../models';

interface AuthProps {
  roles: UserRoleModel[];
  providerRoles: UserRoleModel[];
  branchRoles: UserRoleModel[];
  rootRoles: UserRoleModel[];
  loading: boolean;
  error: any;
}

const store = createStore(
  { name: 'auth' },
  withProps<AuthProps>({
    loading: false,
    error: null,
    providerRoles: [],
    branchRoles: [],
    rootRoles: [],
    roles: [],
  }),
);

Injectable({ providedIn: 'root' });
export class AuthRepository {
  constructor() {}

  providerRoles$ = store.pipe(select((state) => state.providerRoles));
  branchRoles$ = store.pipe(select((state) => state.branchRoles));
  rootRoles$ = store.pipe(select((state) => state.rootRoles));
  roles$ = store.pipe(select((state) => state.roles));
  isLoading$ = store.pipe(select((state) => state.loading));
  error$ = store.pipe(select((state) => state.error));

  setRoles(roles: UserRoleModel[], resourceType: string) {
    switch (resourceType) {
      case 'provider':
        this.setProviderRoles(roles);
        break;
      case 'branch':
        this.setBranchRoles(roles);
        break;
      case 'root':
        this.setRootRoles(roles);
        break;
    }
  }

  setProviderRoles(providerRoles: UserRoleModel[]) {
    store.update((state) => {
      return { ...state, providerRoles };
    });
  }

  setBranchRoles(branchRoles: UserRoleModel[]) {
    store.update((state) => {
      return { ...state, branchRoles };
    });
  }

  setRootRoles(rootRoles: UserRoleModel[]) {
    store.update((state) => {
      return { ...state, rootRoles };
    });
  }

  setLoading(loading: boolean) {
    store.update((state) => {
      return { ...state, loading };
    });
  }

  setError(error: any) {
    store.update((state) => {
      return { ...state, error };
    });
  }

  getRoles(resourceType: 'provider' | 'branch' | 'root' = 'root'): UserRoleModel[] {
    return store.getValue()[`${resourceType}Roles`];
  }
}
