import { Component, inject } from '@angular/core';
import { Subscription, fromEvent, map, merge, of } from 'rxjs';

import { AuthService } from '@src/app/core/services/auth.service';

// Store
import { AppStore } from './app.store';

/**
 * The root component of the application.
 */
@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.less'],
  templateUrl: './app.component.html',
})
export class AppComponent {

  /**
   * Subscription for network status changes.
   */
  networkStatus$: Subscription = Subscription.EMPTY;

  /**
   * The application store.
   */
  readonly appStore = inject(AppStore);

  constructor(private _auth: AuthService) {}

  /**
   * Lifecycle hook that is called after the component is initialized.
   * It checks the network status.
   */
  ngOnInit(): void {
    this.checkNetworkStatus();

    const loggedUser = this._auth.userData;
    
    if(loggedUser) {
      (<any>window).Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'qkh88dfd',
        name: loggedUser.displayName, // Full name
        email: loggedUser.email, // the email for your user
      });
    }
  }

  /**
   * Checks the network status and updates the application store banner accordingly.
   */
  checkNetworkStatus() {
    this.networkStatus$ = merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline'))
      .pipe(map(() => navigator.onLine))
      .subscribe((status) => {
        if (status) {
          this.appStore.clearBanner();
        } else {
          this.appStore.setBanner({ message: 'No internet connection' });
        }
      });
  }

  /**
   * Lifecycle hook that is called when the component is about to be destroyed.
   * It unsubscribes from the network status subscription.
   */
  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
  }
}
