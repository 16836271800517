import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { user } from '@angular/fire/auth';
import { AuthService } from '../services/auth.service';
import { Observable, switchMap, take } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const user = localStorage.getItem('user');
    const isGoogleAPIRequest = req.url.includes('https://maps.googleapis.com/');
    if (user && !isGoogleAPIRequest) {
      return this.authService.getIdToken().pipe(
        switchMap((token) => {
          if (token) {
            const clonedRequest = req.clone({
              headers: req.headers.set('Authorization', `Bearer ${token}`),
            });
            return next.handle(clonedRequest);
          }
          return next.handle(req);
        }),
      );
    }
    return next.handle(req);
  }
}
